import PortifolioList from "../components/Portfolio/PortfolioList";
import Whatsapp1 from "../components/Whatsapp1/Whatsapp1"


const Portifolio = () => {
    return (
        
      <div className=" px-[3%] md:px-[6%]">
       
       
        <PortifolioList />
      </div>
    );
  };
  
  export default Portifolio;
  