import React from "react";
import { workExp } from "../../utils/data";
import css from "./Work.module.scss";
import { motion } from 'framer-motion'
import { draw, fadeIn, slideIn, staggerChildren, textVariant2, zoomIn } from "../../utils/motion";
import Iframe from "react-iframe";
import { FiPhone } from "react-icons/fi";
import { BiMap } from "react-icons/bi";


const ThirdSection = () => {
  return (
    <>
<div className="text-center">
<span className="primaryText">Şubelerimiz</span>

</div>


<motion.section 
      variants={staggerChildren}
      
      whileInView="show"
      viewport={{ once: false, amount: 0.25 }}
      className={`main-bg paddings ${css.wrapper}`}
       // Burada arka plan rengini mavi olarak ayarladık
    >
      <a className="anchor" id="work"></a>

      <div className={`innerWidth sm:pl-20  flexCenter ${css.container}`}>
        {/* heading */}
        
        <div className={`flexCenter ${css.experiences}`}>
         

              <motion.div variants={textVariant2}  className={`flexCenter ${css.exp}`}>
                
                <div className={css.post}>
                
                  <b>Kırklareli Şubemiz</b>
                  <br />
                  <Iframe url="https://maps.google.com/maps?width=520&amp;height=400&amp;hl=tr&amp;q=Maya%20Gayrimenkul%20ve%20Yat%C4%B1r%C4%B1m%20Dan%C4%B1%C5%9Fmanl%C4%B1%C4%9F%C4%B1%20K%C4%B1rklareli+(Maya%20Gayrimenkul%20ve%20Yat%C4%B1r%C4%B1m%20Dan%C4%B1%C5%9Fmanl%C4%B1%C4%9F%C4%B1)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
    
                   width="80%"
                   height="400"
                   margin="0"
                   padding="0"
                   id=""
                   className=""
                        
                /> 	
                
                </div>
                <div className={css.role}>
                <br />
                <b className="text-xl">Kırklareli  Şubemiz<br /> Maya Emlak Gayrimenkul  & Yatırım Danışmanlığı</b>
                 <br /> 
                
                <br /> 
                <div className="grid grid-cols-1 gap-6 py-8 sm:grid-cols-2 md:grid-cols-3">

                <div className="text-center">
                   <div className="icon-box !h-14 !w-14 !bg-primary text-white mx-auto text-2xl">
                     <BiMap />  
                  </div>
                  <h1 className="mt-2 text-lg font-semibold">Ofis Adresi</h1>
                  <p>Karakaş, 100. Yıl Cd. No:26/4</p>
                  <p>39020 Merkez/Kırklareli </p>
                 </div>

                 <div className="text-center">
                   <div className="icon-box !h-14 !w-14 !bg-primary text-white mx-auto text-2xl">
                     <FiPhone />  
                  </div>
                  <h1 className="mt-2 text-lg font-semibold">Telefon</h1>
                    <p>+90 552 831 01 39</p>
                    
                 </div>
                
                 </div>
                </div>

                
              </motion.div>

              <motion.div variants={textVariant2}  className={`flexCenter ${css.exp}`}>
                <div className={css.post}>
                   <br />  
                   <b className="text-xl">Bandırma Şubemiz<br /> Maya Emlak Gayrimenkul  & Yatırım Danışmanlığı</b>
                   <br /> 

                   <div className="grid grid-cols-1 gap-6 py-8 sm:grid-cols-2 md:grid-cols-3">

                      <div className="text-center">
                         <div className="icon-box !h-14 !w-14 !bg-primary text-white mx-auto text-2xl">
                           <BiMap />  
                        </div>
                        <h1 className="mt-2 text-lg font-semibold">Ofis Adresi</h1>
                        <p>Hacı Yusuf, Gözde Apartmanı, İsmet İnönü Cd.</p>
                        <p>No:101, 10200 Bandırma/Balıkesir</p>
                       </div>
                      
                       <div className="text-center">
                         <div className="icon-box !h-14 !w-14 !bg-primary text-white mx-auto text-2xl">
                           <FiPhone />  
                        </div>
                        <h1 className="mt-2 text-lg font-semibold">Telefon</h1>
                          <p>+90 552 831 01 39</p>
                          
                       </div>
                      
                       </div>
                </div>
                <div className={css.role}>
                <b>Bandırma Şubemiz</b>
                  <br />
                  <Iframe url="https://maps.google.com/maps?width=520&amp;height=400&amp;hl=tr&amp;q=Maya%20Emlak%20-%20Band%C4%B1rma%20Band%C4%B1rma+(Maya%20Emlak%20-%20Band%C4%B1rma)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
    
                   width="80%"
                   height="400"
                   margin="0"
                   padding="0"
                   id=""
                   className=""
                        
                /> 	
                </div>

                
              </motion.div>


              <motion.div variants={textVariant2}  className={`flexCenter ${css.exp}`}>
                <div className={css.post}>
                  <b>Çorlu Şubemiz</b>
                  <br />
                  <Iframe url="https://maps.google.com/maps?width=100%25&amp;height=400&amp;hl=en&amp;q=Maya%20Emlak%20%C3%87orlu+(Maya%20Emlak%20%C3%87orlu)&amp;t=&amp;z=15&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
    
                   width="80%"
                   height="400"
                   margin="0"
                   padding="0"
                   id=""
                   className=""
                        
                /> 	
          
                </div>
                <div className={css.role}>
                <br />
                <b className="text-xl">Çorlu  Şubemiz<br /> Maya Emlak Gayrimenkul  & Yatırım Danışmanlığı</b>
                 <br /> 
                
                <br /> 
                <div className="grid grid-cols-1 gap-6 py-8 sm:grid-cols-2 md:grid-cols-3">

                <div className="text-center">
                   <div className="icon-box !h-14 !w-14 !bg-primary text-white mx-auto text-2xl">
                     <BiMap />  
                  </div>
                  <h1 className="mt-2 text-lg font-semibold">Ofis Adresi</h1>
                  <p>Hıdırağa, Salih Omurtak Cd. No:334/A </p>
                   <p>59855 Çorlu/Tekirdağ</p>
                 </div>

                 <div className="text-center">
                   <div className="icon-box !h-14 !w-14 !bg-primary text-white mx-auto text-2xl">
                     <FiPhone />  
                  </div>
                  <h1 className="mt-2 text-lg font-semibold">Telefon</h1>
                    <p>+90 552 831 01 39</p>
                    
                 </div>
                
                 </div>
                </div>

                
              </motion.div>

              <motion.div variants={textVariant2}  className={`flexCenter ${css.exp}`}>
                <div className={css.post}>
                   <br />  
                   <b className="text-xl">Gönen Şubemiz<br /> Maya Emlak Gayrimenkul  & Yatırım Danışmanlığı</b>
                   <br /> 

                   <div className="grid grid-cols-1 gap-6 py-8 sm:grid-cols-2 md:grid-cols-3">

                      <div className="text-center">
                         <div className="icon-box !h-14 !w-14 !bg-primary text-white mx-auto text-2xl">
                           <BiMap />  
                        </div>
                        <h1 className="mt-2 text-lg font-semibold">Ofis Adresi</h1>
                        <p>Kurtuluş Mah. Şehit Ercan Coşkun Cad. </p>
                        <p>No: 30/B Gönen/Balıkesir</p>
                       </div>
                      
                       <div className="text-center">
                         <div className="icon-box !h-14 !w-14 !bg-primary text-white mx-auto text-2xl">
                           <FiPhone />  
                        </div>
                        <h1 className="mt-2 text-lg font-semibold">Telefon</h1>
                          <p>+90 552 831 01 39</p>
                          
                       </div>
                      
                       </div>
                </div>
                <div className={css.role}>
                <b>Gönen Şubemiz</b>
                  <br />
                  <Iframe url="https://maps.google.com/maps?width=100%25&amp;height=400&amp;hl=en&amp;q=Şehit ercan coşkun caddesi,&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
    
                   width="80%"
                   height="400"
                   margin="0"
                   padding="0"
                   id=""
                   className=""
                        
                /> 	
                </div>

                
              </motion.div>


            
        


          <motion.div variants={zoomIn(1, 1)} className={css.progressbar}>
            <motion.div variants={fadeIn("down", "tween", 2, 1.5)} className={css.line}></motion.div>
            <div><div className={css.circle} style={{background: "#286F6C"}}></div></div>
            <div><div className={css.circle} style={{background: "#F2704E"}}></div></div>
            <div><div className={css.circle} style={{background: "#EEC048"}}></div></div>
          </motion.div>

        </div>
      </div>
    </motion.section>
    </>

  );
};

export default ThirdSection;
