import React, { useEffect, useState } from 'react'
import { BiPause, BiPlay } from 'react-icons/bi';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { fetchMemberDetail } from '../../redux/thunk/fetchMemberDatail';
import ReactPlayer from 'react-player/youtube'; // Youtube videosu için, diğer video platformları için uygun sürümleri kullanabilirsiniz
import Back from '../../components/common/Back';
import img from '../../components/images/ozgecmis.png';
import { PuffLoader } from 'react-spinners';
import { FaFacebook, FaInstagram, FaLinkedin, FaPhone, FaWhatsapp, FaYoutube } from 'react-icons/fa';
import Whatsapp1 from '../../components/Whatsapp1/Whatsapp1';
import Call1 from '../../components/Call1/Call1';

function MemberDetail() {


    const { id } = useParams(); // URL'den id parametresini alın
    const memberDetail = useSelector((state) => state.memberDetail);
    const dispatch = useDispatch();

    useEffect(() => {
   
        dispatch(fetchMemberDetail(id));
    
        window.scrollTo(0, 0);
      
          
    
      }, [dispatch, id]);

    
    let member_name = "";
    let member_img = "";
    let member_title = "";
    let member_company = "";
    let member_university = "";
    let member_department = "";
    let member_linkedin = "";
    let member_cv_desc = "";
    let member_video = "";
    let member_whatsapp = "";
    let member_facebook = "";
    let member_instagram = "";
    let phone_number = "";


    if (memberDetail && memberDetail.point) {

        member_name = memberDetail?.client_title;
        member_img = memberDetail?.client_img;
        member_title = memberDetail?.client_description;
        member_company = memberDetail?.point?.point_name;
        member_department = memberDetail?.university_departmant;
        member_linkedin = memberDetail?.linkedin_link;
        member_cv_desc = memberDetail?.cv_description;
        member_video = memberDetail?.youtube_link;
        member_whatsapp = memberDetail?.whatsapp_link;
        member_facebook = memberDetail?.facebook_link;
        member_instagram = memberDetail?.instagram_link;
        member_university = memberDetail?.university;
        phone_number = memberDetail?.phone_number;

        

       
    

    }

    const handleTelefonArama = () => {
      window.location.href = `tel:${phone_number}`;
    };

    const [isPlaying, setPlaying] = useState(false);

    const handlePlayPause = () => {
      setPlaying(!isPlaying);
    };
  
    return (
    <>
       <Back name='Ekip Bilgileri' title={member_name ? member_name : ""} cover={img} /> 
      <div className="px-[3%] md:px-[6%]">
      <Call1 />
      <Whatsapp1 />

      <div className="pt-16 pb-20">
      <div className="text-center">
        <h1 className="heading">
        {member_name ? member_name : ""} - Özgeçmiş Bilgileri
        </h1>
        
      </div>
      <div className="flex flex-wrap gap-24 mt-8  sm:pb-20">
        <div className="relative flex-1 basis-[18rem]">
        {member_img? 
          <img
            src= {member_img} 
            alt=""
            className="object-cover w-full h-full rounded-lg"
          /> :  <div className="wrapper flexCenter" style={{ height: "60vh" }}>
          <PuffLoader
            height="80"
            width="80"
            radius={1}
            color="#4066ff"
            aria-label="puff-loading"
          />
        </div>
        }
        <div className="pb-20 sm:pb-0">
           <div className="absolute w-full p-3 -translate-x-1/2 border-4 border-white rounded-lg -bottom-15 left-1/2 dark:border-main-dark bg-secondary text-slate-200">
               <div className="gap-5 flex-center-between">
                 <h1 className="font-semibold">
                   {member_title  ? member_title : ""} - {member_company ? member_company : ""} Şubesi
                 </h1>
   
                 <div>
                   <h1 className="text-2xl font-bold text-primary">{member_university ? member_university : ""}</h1>
                   <p>Mezuniyet: {member_department ? member_department : ""}</p>
                 </div>
               </div>
             </div>
        </div>
         
        </div>

      <div className="relative flex-1 basis-[22rem]">
      <p>

         <div dangerouslySetInnerHTML={{ __html: member_cv_desc ? member_cv_desc : "" }} />

      </p>

      <div className="mt-4 flex items-center cursor-pointer text-black-500" >
        
        {member_linkedin ? <a target="_blank" href={member_linkedin}><FaLinkedin className="text-3xl mr-2" /></a> : ""}
        {member_instagram ? <a target="_blank" href={member_instagram}><FaInstagram className="text-3xl mr-2" /></a> : ""}
        {member_facebook ?  <a target="_blank" href={member_facebook}><FaFacebook className="text-3xl mr-2" /></a> : ""}
        {member_video ? <a target="_blank" href={member_video}><FaYoutube className="text-3xl mr-2" /></a> : "" }
        {member_whatsapp ? <a target="_blank" href={member_whatsapp}><FaWhatsapp className="text-3xl mr-2" /></a> : ""}
        {phone_number ? <FaPhone  onClick={handleTelefonArama} className="text-2xl mr-2" /> : "" }
        


        
        




      </div>
    </div>
      </div>
    </div>


  {/*  video */}
  {member_video ? <div className="text-center">
             <h1 className="heading">
             {member_name ? member_name : ""} - Video
             </h1>
        </div> : ""}

        {member_video ? <div className="pt-20 pb-20">
          <div className="flex flex-wrap gap-4">
            
            <div className="flex-1 basis-[18rem]">
              <div className="relative overflow-hidden rounded-lg">
              <ReactPlayer
                url={member_video}
                playing={isPlaying}
                controls
                width="100%"
                height="600px"
                onContextMenu={(e) => e.preventDefault()} // Context menu kapatmak için
                config={{
                  youtube: {
                    playerVars: {
                      controls: 1,
                      fs: 1, // Tam ekran butonunu göster
                    },
                  },
                }}
              />
                <div
                  onClick={handlePlayPause}
                  className="absolute top-0 left-0 flex-col w-full h-full bg-black/10 flex-center-center cursor-pointer"
                >
                {isPlaying ? (
                     <p></p>
                    ) 
                  
                   : (
                   <div className="icon-box !text-primary !bg-transparent border !border-primary relative before:absolute before:w-full before:h-full before:rounded-full before:animate-ping before:bg-primary/60">
                      <BiPlay className="text-2xl" />
                 
                  </div>
                    )}
                 
                </div>
              

              </div>
            </div>
          </div>
        </div> : ""}
        
      </div>
    </>
      
    );
  };
  
export default MemberDetail