import { Link } from "react-router-dom";
import { projects } from "../../data/dummyData";
import "./portfolio.css";




const PortifolioList = () => {
  return (
    <div className="pt-10 pb-16">
      <div className="text-center">
        <h1 className="mx-auto sub-heading">Tüm İlanlar</h1>
        <h1 className="heading">Kategorilere Göre İlanlar</h1>
      </div>
      <div className="grid grid-cols-1 gap-4 mt-8 sm:grid-cols-2 md:grid-cols-3">
        {projects.map(({ id, name, number, image,to }) => (
          <div key={id} className="relative w-full group ">
            <div className="overflow-hidden cardCategory">
              <Link to={to} className=" !opacity-100">
                <img
                  src={image}
                  alt={name}
                  className="flex-1  h-[250px] w-full   md:h-[250px] object-cover group-hover:scale-125 transition-a"
                />
              </Link>
            </div>
            <div className="absolute bottom-0 left-0 w-full px-2 py-2 transition-transform bg-gradient-to-t from-black/80 text-slate-100 to-transparent">
              <h1 className="text-lg font-semibold">{name}</h1>
              {/* <p>{number} İlan</p> */}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PortifolioList;
