import React, { useEffect, useState } from "react";

import "./Properties.css";
import useCheapProperties from "../../hooks/useCheapProperties";
import { PuffLoader } from "react-spinners";
import { Route, useHistory } from 'react-router-dom';
import CheapSearch from "./CheapSearch";
import SingleCheapBeforeCard from "./SingleCheapBeforeCard";
import axios from "axios";

const CheapAdverts = () => {


  const [dataTable, setDataTable] = useState([]);


  // card.id'ye uyan priceId'ye sahip eski fiyatı bulun

  const history = useHistory();

  
  const { data, isError, isLoading } = useCheapProperties();
  useEffect(() => {

    axios.
    get("https://mayadangayrimenkul.com.tr/api/cheapAdvertids")
    .then(response=> setDataTable(response.data));
   


    window.scrollTo(0, 0);
  
      

  }, []);



  const ScrollToTop = () => {
    window.scrollTo(0, 0);
    return null;
  };
  

 
  const [filter, setFilter] = useState("");
  if (isError) {
    return (
      <div className="wrapper">
        <span>Error while fetching data</span>
      </div>
    );
  }
  console.log(isError);
  if (isLoading) {
    return (
      <div className="wrapper flexCenter" style={{ height: "60vh" }}>
        <PuffLoader
          height="80"
          width="80"
          radius={1}
          color="#4066ff"
          aria-label="puff-loading"
        />
      </div>
    );
  }


  return (

    

    <div className="wrapper">
      <Route component={ScrollToTop} />

      <CheapSearch filter={filter} setFilter={setFilter} />
      <div className="px-[3%] md:px-[6%]">
      <div className=" pt-10 pb-16 flex items-start justify-around gap-32 wrapper-alt-div">
 
      <div className="flex flex-wrap gap-4 mt-8">
          {data.length == 0 ? <p>Aramada bir ilan bulunamadı</p> : 
            // data.map((card, i)=> (<PropertyCard card={card} key={i}/>))

            data
              .filter(
                (property) =>
                (property.title && typeof property.title === 'string' && property.title.toLowerCase().includes(filter.toLowerCase())) ||
                  property.location?.city?.name?.toLowerCase().includes(filter.toLowerCase()) ||
                  property.location?.district?.name?.toLowerCase().includes(filter.toLowerCase()) ||
                  property.location?.town?.name?.toLowerCase().includes(filter.toLowerCase()) ||
                  property.categoryTypeName?.toLowerCase().includes(filter.toLowerCase ()) ||
                  property.tradeTypeName?.toLowerCase().includes(filter.toLowerCase ()) 
                  
              )
             
              .map((card, i) => (
                <SingleCheapBeforeCard  dataTable={dataTable}  card={card} key={i} />
              ))
          }
        </div>
        
      </div>
    </div>
    </div>
  );
};

export default CheapAdverts;

