import { BiCreditCard, BiGlobe, BiHomeAlt } from "react-icons/bi";
import Call1 from "../Call1/Call1";
import Whatsapp1 from "../Whatsapp1/Whatsapp1";

const AboutUs = () => {
  return (
    <div className="pt-16 pb-20">
      
      <div className="flex flex-wrap gap-24">
        <div className="relative flex-1 basis-[18rem] border">
          <img
            src="/images/property (16).jpg"
            alt=""
            className="object-cover w-full h-full rounded-lg"
          />
          <img
            src="/images/property (26).jpg"
            alt=""
            className="absolute object-cover w-48 h-64 border-4 border-white rounded-lg sm:w-72 sm:h-80 dark:border-dark -bottom-20 -right-2 md:-right-20"
          />
        </div>
      
        <Whatsapp1 />
        <Call1 />
       
       
        <div className="relative flex-1 basis-[22rem] ">
          {/* <div className="text-center">
            <h1 className="sub-heading">HAKKIMIZDA</h1>
          </div> */}
          
          <h1 className="heading">Biz Kimiz ?

</h1>
          <p className="mt-3">
          Maya Gayrimenkul ve Yatırım Danışmanlığı olarak 2021 yılında Kırklareli’de gayrimenkul sektöründe müşterilerimizin beklentilerini, ihtiyaçlarına ve olanaklarına göre en iyi analizi gerçekleştirerek satış ve kiralama öncesi ve sonrası en hızlı, güvenilir, kaliteli hizmeti sunmak amacı ile yola çıktık. Kısa süre içerisinde bölgemizde sağladığımız müşteri memnuniyeti ile bölgemizde markalaşarak büyümeye devam ettik. Hizmet politikalarımızdan taviz vermeden alanında uzman gayrimenkul uzmanlarımız ile 2. Şubemizi 2022 yılında Bandırma’da hizmete geçirdik.
          <br />

            Ülkemizin lokomotifi olan gayrimenkul sektöründe ki tüm gelişmeleri yakından takip eden Maya Gayrimenkul ve Yatırım Danışmanlığı, emin adımlarla yolunda yürümeye ve büyümeye devam edecektir.
            <br />
          Gayrimenkul sektöründe profesyonel ve dinamik kadromuz ile müşterilerimizin memnuniyetini esas alarak ihtiyaçlarına uygun çözümler üreterek hızlı ve güvenilir hizmet sunmaktayız.
          </p>
          <div className="mt-4">
            <div className="flex-align-center gap-x-2">
              <div className="icon-box text-primary !bg-primary/20">
                <BiHomeAlt />
              </div>
              <div>
                <h1 className="font-semibold capitalize">
                  Gayrimenkul
                </h1>
                <p>
                Maya Gayrimenkul & Yatırım Danışmanlığı olarak sizlere gayrimenkul alım-satım işlemlerinizde yardımcı olmak için her zaman hazırız!
                </p>
              </div>
            </div>

            <div className="mt-3 flex-align-center gap-x-2">
              <div className="icon-box text-primary !bg-primary/20">
                <BiGlobe />
              </div>
              <div>
                <h1 className="font-semibold capitalize">
                  Yatırım
                </h1>
                <p>
                Maya Gayrimenkul & Yatırım Danışmanlığı olarak sektörde tercihe layık bir marka olarak hizmete devam etmekteyiz.
                </p>
              </div>
            </div>

            <div className="mt-3 flex-align-center gap-x-2">
              <div className="icon-box text-primary !bg-primary/20">
                <BiCreditCard />
              </div>
              <div>
                <h1 className="font-semibold capitalize">
                  Danışmanlık
                </h1>
                <p>
                Maya Gayrimenkul & Yatırım Danışmanlığı bünyesindeki, alanında başarılı danışmanlarımız ile iletişimlerinizde memnuniyet güvencesi.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
