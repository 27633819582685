import { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { uiStore } from "../../redux/slice/uiSlice";
import { motion } from "framer-motion";
import useLinks from "../../hooks/useLinks";

const Dropdown = () => {
  const { currentLink, isDropdownOpen, position } = useSelector(uiStore);
  const { subLinks } = currentLink;
  const container = useRef(null);
  const { data, isError, isLoading } = useLinks();

  useEffect(() => {
    const dropdown = container.current;
    dropdown.style.left = `${position}px`;
  }, [position]);

  return (
    <div className="hidden sm:block">
      {currentLink && (
        <motion.div
          className={`dropdown hidden z-50 transition-a fixed top-11 left-1/2 -translate-x-1/2 !rounded-xl w-52 bg-white dark:bg-dark-light card-shadow dark:shadow-none ${
            isDropdownOpen && subLinks && "show"
          }`}
          initial={{ y: -30, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          exit={{ y: -30, opacity: 0 }}
          ref={container}
        >
          <div className={`${subLinks && "p-2"}`}>
          {data &&
             data.map(({ subLinks }) => (
               subLinks && // subLinks varsa devam et
               subLinks.map(({ id, linkText, url }) => (
                 <NavLink
                   key={id}
                   to={`/team/${id}`}
                   className="p-2 space-x-3 rounded-lg flex-align-center sm:cursor-pointer hover:bg-slate-100 dark:hover:bg-hover-color-dark"
                 >
                   {linkText}
                 </NavLink>
               ))
             ))}
          </div>
        </motion.div>
      )}
    </div>
  );
};

export default Dropdown;
