import React from 'react'
import AboutUs from '../components/AboutUs/AboutUs'

function About() {
  return (
    <div className=" px-[3%] md:px-[6%]">

      
       <AboutUs />
       
    </div>
  )
}

export default About