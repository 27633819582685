import { BiBed, BiMap, BiMapAlt, BiTab } from "react-icons/bi";
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { setCardDetail } from '../../redux/slice/cardDetailSlice';
import CardHoverIcons from "../../components/AdvertCard/CardHoverIcons";
import axios from "axios";
import { useEffect, useState } from "react";

const SingleCheapBeforeCard = ({ card, dataTable }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const handleCardClick = () => {
    dispatch(setCardDetail(card));
    // Karta tıklanıldığında kart detay sayfasına gitmek için yönlendirme yapın
  };

  const formatlaTL = (fiyat) => {
    const formattedFiyat = new Intl.NumberFormat('tr-TR', {
      currency: 'TRY',
      minimumFractionDigits: 2,
    }).format(fiyat);

    return formattedFiyat.slice(0, -3); // "TRY" sembolü ve boşluğu kes
  };



  

  // card.id'ye uyan priceId'ye sahip eski fiyatı bulun
  const oldFee = dataTable.find(priceData => parseInt(priceData.advertID) === card.id)?.oldPrice;


  return (
    <div onClick={handleCardClick} className="flex-1 basis-[18rem] shadow-light dark:border-card-dark border rounded-lg overflow-hidden relative group">
      <a href={`/advert/${card.id}`}>
        <div>
          <div className="group !opacity-100 overflow-hidden relative">
            <img
              src={`https://imaj.emlakjet.com/original${card.images[0]}`}
              alt="ilan"
              className="flex-1 h-[250px] w-full md:h-[250px] object-cover group-hover:scale-125 transition-a"
            />
            <CardHoverIcons />
            <div className="absolute bottom-0 left-0 w-full px-2 py-2 transition-transform bg-gradient-to-t from-black/80 sm:translate-y-10 group-hover:translate-y-0 to-transparent">
              <div className="text-white flex-align-center gap-x-2">
                <BiMap />
                <p>{card.location.city.name}</p>
              </div>
            </div>
          </div>
          <div className="absolute top-2 left-2 flex-align-center gap-x-2">
            <span className="py-[3px] px-3 text-sm rounded-full capitalize text-white bg-secondary">
              İlan Türü: {card.tradeTypeName} / {card.categoryTypeName}
            </span>
          </div>
          <div className="p-3">
            <h1 className="text-lg font-bold capitalize">{card.title}</h1>
            <div className="flex justify-between mt-3">
              <div className="flex-align-center gap-x-2">
                <div className="icon-box !w-7 !h-7 bg-primary/20 hover:!bg-primary/40 text-primary">
                  <BiMapAlt />
                </div>
                <p className="text-sm"><b>Konum: </b> {card.location.city.name} - {card.location.district.name} - {card.location.town.name}</p>
              </div>
            </div>
            <div className="mt-4 flex-center-between">
              <h1 className="text-lg font-semibold text-primary">{formatlaTL(card.priceDetail.price)} TL</h1>
               {oldFee && <h2><s>{formatlaTL(oldFee)} TL</s></h2>} 
              <button onClick={handleCardClick} className="btn btn-secondary">İlan Detayı</button>
            </div>
          </div>
        </div>
      </a>
    </div>
  );
};

export default SingleCheapBeforeCard;
