import { setPointDetail } from "../slice/pointDetailSlice";

export const fetchPointDetail = (id) => async (dispatch) => {
    try {
      const response = await fetch(`https://mayadangayrimenkul.com.tr/api/point/${id}`);
      if (!response.ok) {
        throw new Error('Şube detayı yüklenemedi.');
      }
      const data = await response.json();
  
      // Kart detaylarını Redux store'a yükle
      dispatch(setPointDetail(data));
    } catch (error) {
      console.error('Hata:', error);
      throw error;
    }
  };
  