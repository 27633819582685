import "./App.css"

import { QueryClient, QueryClientProvider } from "react-query";
import store from "./redux/store";
import { Provider } from 'react-redux';
import Pages from "./layout/Pages";




function App() {
  

  const queryClient = new QueryClient();

  return (

    
    <QueryClientProvider client={queryClient}>
       <Provider store={store}>
         <Pages />
      </Provider>
     
    </QueryClientProvider>  
  

  )
}

export default App
