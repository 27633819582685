import React from "react";
import "./recent.css"
import css from "./Recent.scss";
import { BiBed, BiMap, BiMapAlt, BiTab } from "react-icons/bi";
import { Link } from "react-router-dom";
import CardHoverIcons from "../../AdvertCard/CardHoverIcons";
import CardLabels from "../../AdvertCard/CardLabels";
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { setCardDetail } from '../../../redux/slice/cardDetailSlice'; // Redux Toolkit'dan gelen action'ı import edin

const RecentCard = ({ card }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  


  const handleCardClick = () => {
    // Kart detayını Redux'da güncelleyin
    dispatch(setCardDetail(card));
    
    // Karta tıklanıldığında kart detay sayfasına gitmek için yönlendirme yapın
    history.push(`/advert/${card.id}`);

    
  };

  return (
    <div className='box shadow py-5 px-5 card-container' onClick={handleCardClick}>
      <div className='imgadvert' >
        <img src={card.image} alt='' />
      </div>
      <div className='textadvert'>
        <div className='category flex'>
          {/* Kategori veya diğer bilgiler */}
        </div>
        <p className="sol"> 
        <h4 className="text-xl font-bold">{card.title}</h4>
          <i className='fa fa-location-dot'></i> {card.city}
        </p>
      </div>
      <div className='button flex'>
        <div>
          <button className='btn2'>{card.price} TL</button> / 
        </div>
        <span>&nbsp; {card.createdAt.substring(0, 10)}</span>
      </div>
    </div>
  );
};

export default RecentCard;
