import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import SingleCheapBeforeCard from "../../pages/CheapAdverts/SingleCheapBeforeCard";
import { PuffLoader } from "react-spinners";
import useCheapProperties from "../../hooks/useCheapProperties";
import axios from "axios";

const AdvertCheapCards = () => {
    const history = useHistory();
    const [dataTable, setDataTable] = useState([]);
    const { data: veri, isError, isLoading } = useCheapProperties();

    useEffect(() => {
        // Veri çekme işlemi
        axios.get("https://mayadangayrimenkul.com.tr/api/cheapAdvertids")
            .then(response => setDataTable(response.data))
            .catch(error => console.error("Error fetching data:", error));

        window.scrollTo(0, 0);
    }, []);

    console.log("VERİ",veri);
    // Veri yüklenirken ya da veri boşsa loading göstergesini ekliyoruz
    if (isLoading ) {
        return (
            <div className="wrapper flexCenter" style={{ height: "60vh" }}>
                <PuffLoader
                    height="80"
                    width="80"
                    radius={1}
                    color="#4066ff"
                    aria-label="puff-loading"
                />
            </div>
        );
    }
    else if(veri.length === 0) {
      return (
        <></>
      )
    }

    // Veriler geldiyse bileşeni render et
    return (
        <div className="px-[3%] md:px-[6%]">
            <div className="pt-10 pb-16">
                <div className="text-center">
                    <a href="/cheapAdverts">
                        <h1 className="mx-auto sub-heading">TÜMÜNÜ GÖR</h1>
                    </a>
                    <h1 className="heading">Fiyatı Düşen İlanlar</h1>
                </div>
                <div className="flex flex-wrap gap-4 mt-8">
                    {veri.slice(0, 4).map((card, i) => (
                        <SingleCheapBeforeCard dataTable={dataTable} card={card} key={i} />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default AdvertCheapCards;
