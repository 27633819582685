import { setMemberDetail } from "../slice/memberDetailSlice";

export const fetchMemberDetail = (id) => async (dispatch) => {
    try {
      const response = await fetch(`https://mayadangayrimenkul.com.tr/api/member/${id}`);
      if (!response.ok) {
        throw new Error('Çalışan detayı yüklenemedi.');
      }
      const data = await response.json();
  
      // Kart detaylarını Redux store'a yükle
      dispatch(setMemberDetail(data));
    } catch (error) {
      console.error('Hata:', error);
      throw error;
    }
  };
  