import { createSlice } from '@reduxjs/toolkit';

const memberDetailSlice = createSlice({
  name: 'memberDetail',
  initialState: null,
  reducers: {
    setMemberDetail: (state, action) => action.payload,
    clearMemberDetail: () => null,
  },
});

export const { setMemberDetail, clearMemberDetail } = memberDetailSlice.actions;

export default memberDetailSlice.reducer;