import React from "react";
import { Link } from "react-router-dom";

const HeroBtn = ({ title, underline, css,to }) => {
  return (
    <div className={`${`${css}`} relative group`}>
      <div className="text-white font-semibold px-1.5 text-[11px] sm:text-[14px]   ">
        <Link  to={to}>
          <button >{title}</button>
        </Link>
        
      </div>
      <div
        className={`absolute h-[2.5px] group-hover:bg-[#FFFFFF] ${underline}  w-full duration-200 ease-out`}
      ></div>
    </div>
  );
};

export default HeroBtn;
