import { createSlice } from '@reduxjs/toolkit';

const cardDetailSlice = createSlice({
  name: 'cardDetail',
  initialState: null,
  reducers: {
    setCardDetail: (state, action) => action.payload,
    clearCardDetail: () => null,
  },
});

export const { setCardDetail, clearCardDetail } = cardDetailSlice.actions;

export default cardDetailSlice.reducer;