import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Route, useParams } from 'react-router-dom';
import { PuffLoader } from 'react-spinners';
import { clearCardDetail } from '../../redux/slice/cardDetailSlice';
import { fetchCardDetail } from '../../redux/thunk/fetchCardDetail'; // Redux Thunk işlevini içe aktarın
import { useHistory } from 'react-router-dom';
import "./Property.css";
import { MdLocationPin } from 'react-icons/md';
import Iframe from 'react-iframe';
import { BiArrowBack, BiHeart } from 'react-icons/bi';
import {AiOutlineArrowRight} from "react-icons/ai"
import Whatsapp1 from '../Whatsapp1/Whatsapp1';
import Call1 from '../Call1/Call1';


const Property = () => {

  const [buyukResimIndex, setBuyukResimIndex] = useState(0);


  const history = useHistory();

  const { id } = useParams(); // URL'den id parametresini alın
  const cardDetail = useSelector((state) => state.cardDetail);
  const dispatch = useDispatch();

  let tarih = "";
  let formattedDate = "";
  let kucukResimler = [];
  let kategori = "";
  let emlak = "";
  let tip = "";
  let oda_sayisi = "";
  let trValue = "";
  let aciklama = "";
  let fiyat= "";
  let tapu_durum = "";
  let metrekare = "";
  let net_metrekare = "";
  let brut_metrekare = "";
  let kat_sayisi = "";
  let isitma_tipi = "";
  let banyo_sayisi = "";
  let esya_durumu = "";
  let kullanim_durumu = "";
  let bina_yasi = "";
  let kredi= "";
  let takas = "";
  let kat_karsiligi = "";


  if (cardDetail && cardDetail.result && cardDetail.result.images && Array.isArray(cardDetail.result.images)) {
   kucukResimler = cardDetail?.result.images;

  }

  
  if (cardDetail && cardDetail.result && cardDetail.result.createdAt ) {
    tarih = cardDetail?.result.createdAt;

 
      const turkishMonths = [
        'Ocak', 'Şubat', 'Mart', 'Nisan',
        'Mayıs', 'Haziran', 'Temmuz', 'Ağustos',
        'Eylül', 'Ekim', 'Kasım', 'Aralık'
      ];
      
      // Türkçe gün isimleri dizisi
     
      
      // Tarih ve saat nesnesi oluşturma
      const date = new Date(tarih);
      
       formattedDate = `${date.getDate()} ${turkishMonths[date.getMonth()]} ${date.getFullYear()} `;
    
 
   }

   if (cardDetail && cardDetail.result && cardDetail.result.metadata ) {
     kategori = cardDetail.result.metadata.category?.name?.tr;

     emlak = cardDetail.result.metadata.tradeType?.name?.tr;

     tip = cardDetail.result.metadata.estateType?.name?.tr;

   }


   const formatlaTL = (fiyat) => {
    const formattedFiyat = new Intl.NumberFormat('tr-TR', {
      
        currency: 'TRY',
        minimumFractionDigits: 2,
    }).format(fiyat);

    return formattedFiyat.slice(0, -3); // "TRY" sembolü ve boşluğu kes
};
   


   
 

  

  if (cardDetail && cardDetail.result && Array.isArray(cardDetail.result.properties)) {


    cardDetail?.result.properties.forEach(property => {
    if (property.key === "title") {
      trValue = property.value?.label?.tr;
      return; // return kullanarak forEach döngüsünü sonlandırabiliriz.
    }
    if (property.key === "description") {
      aciklama = property.value?.label?.tr;
      return; // return kullanarak forEach döngüsünü sonlandırabiliriz.
    }
    if (property.key === "price") {
      fiyat = property.value?.label?.tr;
      return; // return kullanarak forEach döngüsünü sonlandırabiliriz.
    }

    if (property.key === "deed_status") {
      tapu_durum = property.value?.items[0]?.name?.tr;
      return; // return kullanarak forEach döngüsünü sonlandırabiliriz.
    }

    if (property.key === "square") {
      metrekare = property.value?.label?.tr;
      return; // return kullanarak forEach döngüsünü sonlandırabiliriz.
    }

    
    if (property.key === "net_square") {
      net_metrekare = property.value?.label?.tr;
      return; // return kullanarak forEach döngüsünü sonlandırabiliriz.
    }
   
    if (property.key === "gross_square") {
      brut_metrekare = property.value?.label?.tr;
      return; // return kullanarak forEach döngüsünü sonlandırabiliriz.
    }

    if (property.key === "floor_count") {
      kat_sayisi = property.value?.label?.tr;
      return; // return kullanarak forEach döngüsünü sonlandırabiliriz.
    }

    if (property.key === "heating_type") {
      isitma_tipi = property.value?.items[0]?.name?.tr;
      return; // return kullanarak forEach döngüsünü sonlandırabiliriz.
    }

    if (property.key === "bath_count") {
      banyo_sayisi = property.value?.items[0]?.name?.tr;
      return; // return kullanarak forEach döngüsünü sonlandırabiliriz.
    }

    if (property.key === "furniture_status") {
      esya_durumu = property.value?.items[0]?.name?.tr;
      return; // return kullanarak forEach döngüsünü sonlandırabiliriz.
    }

    if (property.key === "usability") {
      kullanim_durumu = property.value?.items[0]?.name?.tr;
      return; // return kullanarak forEach döngüsünü sonlandırabiliriz.
    }

    if (property.key === "room_count") {
      oda_sayisi = property.value?.items[0]?.name?.tr;
      return; // return kullanarak forEach döngüsünü sonlandırabiliriz.
    }

    if (property.key === "build_age") {
      bina_yasi = property.value?.items[0]?.name?.tr;
      return; // return kullanarak forEach döngüsünü sonlandırabiliriz.
    }

    if (property.key === "suitability_for_credit") {
      kredi = property.value?.items[0]?.name?.tr;
      return; // return kullanarak forEach döngüsünü sonlandırabiliriz.
    }

    if (property.key === "trade") {
      takas = property.value?.items[0]?.name?.tr;
      return; // return kullanarak forEach döngüsünü sonlandırabiliriz.
    }

    if (property.key === "flat_for_construction") {
      kat_karsiligi = property.value?.items[0]?.name?.tr;
      return; // return kullanarak forEach döngüsünü sonlandırabiliriz.
    }


    
  });
  }

  

  

  useEffect(() => {
   
    dispatch(fetchCardDetail(id));

    window.scrollTo(0, 0);
  
      

  }, [dispatch, id]);




  if (!cardDetail) {
   return (
        <div className="wrapper flexCenter" style={{ height: "60vh" }}>
          <PuffLoader
            height="80"
            width="80"
            radius={1}
            color="#4066ff"
            aria-label="puff-loading"
          />
        </div>
   )
  }


  

  const tabloStili = {
    borderCollapse: 'collapse',
    width: '300px', // İsteğe bağlı, tablonun genişliğini ayarlayabilirsiniz.
  };
  
  const hücreStili = {
    borderBottom: '1px dotted gray', // Yalnızca alt border
    padding: '2%', // İsteğe bağlı, hücre içeriği için padding ayarı
  }; 

  const detayh1 = {
    fontSize: "18px",
      color:"#333" ,
      padding: "15px 0 12px",
      height: "18px",
      float: "left",
      maxWidth: "710px",
      whitespace: "nowrap",
  borderBottom: "10px solid #dbdbdb",
   }
  const detaytitlediv={
    
    marginBottom: "10px",
   
    color:"#333",
  }
  

    
const ileriGit = () => {

  const sonSatirIndis = kucukResimler.length - 1;

  if(buyukResimIndex !== sonSatirIndis )  {
  setBuyukResimIndex((prevIndex) => (prevIndex + 1) % kucukResimler.length);
  }
  
  
  scrollX(140); // Sağa doğru 100 piksel kaydırma

};

const geriGit = () => {

  

  if(buyukResimIndex !== 0 )  {
    setBuyukResimIndex((prevIndex) =>
    prevIndex === 0 ? kucukResimler.length - 1 : prevIndex - 1
  );
  }
  

  scrollX(-140); // Sola doğru 100 piksel kaydırma


}

const ileriGitMobil = () => {

  const sonSatirIndis = kucukResimler.length - 1;

  if(buyukResimIndex !== sonSatirIndis )  {
  setBuyukResimIndex((prevIndex) => (prevIndex + 1) % kucukResimler.length);
  }
  
  
  scrollXMobil(140); // Sağa doğru 100 piksel kaydırma

};

const geriGitMobil = () => {

  

  if(buyukResimIndex !== 0 )  {
    setBuyukResimIndex((prevIndex) =>
    prevIndex === 0 ? kucukResimler.length - 1 : prevIndex - 1
  );
  }
  

  scrollXMobil(-140); // Sola doğru 100 piksel kaydırma


}





  const scrollX = (amount) => {
    const container = document.getElementById('resimikaydir');
    if (container) {
      container.scrollLeft  += amount;
    
    }
  };

  const scrollXMobil = (amount) => {
    const container = document.getElementById('resimikaydirMobil');
    if (container) {
      container.scrollLeft  += amount;
    
    }
  };
 
 
  

  return (
    <div className="div1" key={JSON.stringify("")}>



    <div className='en büyük div sm:py-10 sm:px-10 focus:outline-none'>
      <div className='w-full '>
        <div className='flex justify-between align-center gap-4'>
          
          
          <h1 className='text-xl font-bold mr-5 p-4  baslık'>{ trValue }
      
      </h1>
     
    </div>
      
    <div className="w-full h-0.5 bg-slate-200 "></div>
    <div className='px-4 py-2 flex items-center justify-between text-primary gap-4 '>
       <div className="flex items-center gap-2">
         <div className="icon-box !w-7 !h-7  bg-primary/20 hover:bg-primary/40 text-primary ">
           <BiHeart />
         </div>
         <p >Favorilerime Ekle</p>
       </div>
     </div>
    </div>



  <div className='hidden sm:flex col alt-div justify-center p-[1rem] gap-2  item-center  ' >
  <div className="lg:flex">

  <div className='resim-slider lg:w-3/5 w-full m-[1rem] h-auto'>

          {/* Büyük resmi göstermek için bir <img> elementi */}
          <div className='buyuk-resim h-[30rem] w-full'>

  
          <img
             className='object-contain	bg-dark'
            
              src={kucukResimler[buyukResimIndex]?.originalImageUrl}
            
              style={{ width: '100%', height: '100%' }}
            /> 
           
            {/* Sağa ve sola gitme düğmeleri */}
            <button className='solaGit' onClick={geriGit}>
          <BiArrowBack/>
            </button>
            <button className='sagaGit' onClick={ileriGit}> <AiOutlineArrowRight/></button>
           
          
          </div>
    
          {/* Küçük resimleri göstermek için bir liste */}
          <div id="resimikaydir" className="overflow-x-auto  whitespace-nowrap">
           <div  className="inline-block pr-4">
             {/* Boş bir blok ekleyerek galerinin başlangıcını ayarlıyoruz */}
           </div>
           <div  className="inline-block">
             {kucukResimler?.map((kucukResimURL, index) => (
               <img
                 
                 key={index}
                 src={kucukResimURL.originalImageUrl}
                 onClick={() => setBuyukResimIndex(index)}
                 className={`object-fill h-[100px] w-[130px] lg:w-[130px] m-[5px] inline-block ${
                   index === buyukResimIndex
                     ? 'border-2 border-solid border-orange-500' 
                     : ''
                 }`}
               />
             ))}
           </div>
           <div className="inline-block pl-4">
             {/* Boş bir blok ekleyerek galerinin sonunu ayarlıyoruz */}
           </div>
         </div>
          
    
        </div>
        <Call1 />
        <Whatsapp1 />
    <div className='lg:pl-20  h-auto '>
     

     <div className="justify-center item-center">
      <div className=' w-full  text-center'>
     
      <h1 className="text-xl text-center sm:text-left"><b className='text-blue-800  '> {formatlaTL(fiyat)} TL</b></h1>
         <h1 className="text-center sm:text-left"><b className='text-blue-800  '>{ cardDetail?.result?.location?.city} / { cardDetail?.result?.location?.district} / { cardDetail?.result?.location?.town}</b></h1>
      </div>
       
        <table className='tabloStili'>
             <tbody>
             
                 <tr >
                   <td  style={hücreStili} className="sütun-2-satır" colSpan={2}><b className=''>{cardDetail?.city} {cardDetail?.address}</b></td>
                   
                 </tr>
                 
                 <tr >
                   <td style={hücreStili} className="sütun-2-satır"><b className="">İlan No:</b> </td>
                   <td style={hücreStili} className="sütun-2-satır" >{cardDetail?.result?.id}</td>
                 </tr>

                 <tr >
                   <td style={hücreStili} className="sütun-2-satır" ><b className="">İlan Tarihi</b> </td>
                   <td style={hücreStili} className="sütun-2-satır" >{formattedDate} </td>
                 </tr>

                 <tr >
                   <td style={hücreStili} className="sütun-2-satır" ><b className="">İlan Kategori</b> </td>
                   <td style={hücreStili} className="sütun-2-satır" > {emlak} {kategori}  </td>
                 </tr>

                 <tr >
                   <td style={hücreStili} className="sütun-2-satır" ><b className="">Emlak Türü</b> </td>
                   <td style={hücreStili} className="sütun-2-satır" >{tip}  </td>
                 </tr>

                 

               

                  {kredi ? 
                 <tr >
                   <td style={hücreStili} className="sütun-2-satır" > <b className="">Krediye Uygunluk</b> </td>
                   <td style={hücreStili} className="sütun-2-satır" > {kredi}  </td>
                 </tr>
                : ''}    

            

                 {tapu_durum ? 
                 <tr >
                   <td style={hücreStili} className="sütun-2-satır" > <b className="">Tapu Durumu </b> </td>
                   <td style={hücreStili} className="sütun-2-satır" > {tapu_durum}  </td>
                 </tr>
                : ''}

                {oda_sayisi ? 
                 <tr >
                   <td style={hücreStili} className="sütun-2-satır" > <b className="">Oda Sayısı: </b> </td>
                   <td style={hücreStili} className="sütun-2-satır" > {oda_sayisi}  </td>
                 </tr>
                : ''} 
                 
                 {metrekare ? 
                 <tr >
                   <td style={hücreStili} className="sütun-2-satır" > <b className="">m² (Metrekare)</b> </td>
                   <td style={hücreStili} className="sütun-2-satır" > {metrekare}  </td>
                 </tr>
                : ''}

                
                  {net_metrekare ? 
                 <tr >
                   <td style={hücreStili} className="sütun-2-satır" > <b className="">m² (Net Metrekare)</b> </td>
                   <td style={hücreStili} className="sütun-2-satır" > {net_metrekare}  </td>
                 </tr>
                : ''}

                 {brut_metrekare ? 
                 <tr >
                   <td style={hücreStili} className="sütun-2-satır" > <b className="">m² (Brüt Metrekare)</b> </td>
                   <td style={hücreStili} className="sütun-2-satır" > {brut_metrekare}  </td>
                 </tr>
                : ''}


               {kat_sayisi ? 
                 <tr >
                   <td style={hücreStili} className="sütun-2-satır" > <b className="">Kat Sayısı</b> </td>
                   <td style={hücreStili} className="sütun-2-satır" > {kat_sayisi}  </td>
                 </tr>
                : ''}

             {bina_yasi ? 
                 <tr >
                   <td style={hücreStili} className="sütun-2-satır" > <b className="">Bina Yaşı</b> </td>
                   <td style={hücreStili} className="sütun-2-satır" > {bina_yasi}  </td>
                 </tr>
                : ''}

              {isitma_tipi ? 
                 <tr >
                   <td style={hücreStili} className="sütun-2-satır" > <b className="">Isıtma</b> </td>
                   <td style={hücreStili} className="sütun-2-satır" > {isitma_tipi}  </td>
                 </tr>
                : ''}


              {banyo_sayisi ? 
                 <tr >
                   <td style={hücreStili} className="sütun-2-satır" > <b className="">Banyo Sayısı</b> </td>
                   <td style={hücreStili} className="sütun-2-satır" > {banyo_sayisi}  </td>
                 </tr>
                : ''}

                {esya_durumu ? 
                 <tr >
                   <td style={hücreStili} className="sütun-2-satır" > <b className="">Eşya Durumu</b> </td>
                   <td style={hücreStili} className="sütun-2-satır" > {esya_durumu}  </td>
                 </tr>
                : ''}        

                {kullanim_durumu ? 
                 <tr >
                   <td style={hücreStili} className="sütun-2-satır" > <b className="">Kullanım Durumu</b> </td>
                   <td style={hücreStili} className="sütun-2-satır" > {kullanim_durumu}  </td>
                 </tr>
                : ''}  

                {takas ? 
                 <tr >
                   <td style={hücreStili} className="sütun-2-satır" > <b className="">Takas Durumu</b> </td>
                   <td style={hücreStili} className="sütun-2-satır" > {takas}  </td>
                 </tr>
                : ''}    

              {kat_karsiligi ? 
                 <tr >
                   <td style={hücreStili} className="sütun-2-satır" > <b className="">Kat Karşılığı</b> </td>
                   <td style={hücreStili} className="sütun-2-satır" > {kat_karsiligi}  </td>
                 </tr>
                : ''}  

               


              
       
               
             </tbody>
           </table>

     </div>
      
    </div>
</div>

</div>

<div className='lg:hidden md:hidden block flex col alt-div justify-center p-[1rem] gap-2  item-center  ' >


<div className='resim-slider lg:w-3/5 w-full m-[1rem] h-auto'>

        {/* Büyük resmi göstermek için bir <img> elementi */}
        <div className='buyuk-resim h-[30rem] w-full'>


        <img
           className='object-contain	bg-dark'
          
            src={kucukResimler[buyukResimIndex]?.originalImageUrl}
          
            style={{ width: '100%', height: '100%' }}
          /> 
         
          {/* Sağa ve sola gitme düğmeleri */}
          <button className='solaGit' onClick={geriGitMobil}>
        <BiArrowBack/>
          </button>
          <button className='sagaGit' onClick={ileriGitMobil}> <AiOutlineArrowRight/></button>

        </div>
  
        {/* Küçük resimleri göstermek için bir liste */}
        <div id="resimikaydirMobil" className="overflow-x-auto  whitespace-nowrap">
         <div  className="inline-block pr-4">
           {/* Boş bir blok ekleyerek galerinin başlangıcını ayarlıyoruz */}
         </div>
         <div  className="inline-block">
           {kucukResimler?.map((kucukResimURL, index) => (
             <img
               
               key={index}
               src={kucukResimURL.originalImageUrl}
               onClick={() => setBuyukResimIndex(index)}
               className={`object-fill h-[100px] w-[130px] lg:w-[130px] m-[5px] inline-block ${
                 index === buyukResimIndex
                   ? 'border-2 border-solid border-orange-500' 
                   : ''
               }`}
             />
           ))}
         </div>
         <div className="inline-block pl-4">
           {/* Boş bir blok ekleyerek galerinin sonunu ayarlıyoruz */}
         </div>
       </div>
        
  
      </div>

  <div className='lg:pl-20  h-auto '>
   

   <div className="justify-center item-center">
    <div className=' w-full  text-center'>
   
    <h1 className="text-xl text-center sm:text-left"><b className='text-blue-800  '> {formatlaTL(fiyat)} TL</b></h1>
       <h1 className="text-center sm:text-left"><b className='text-blue-800  '>{ cardDetail?.result?.location?.city} / { cardDetail?.result?.location?.district} / { cardDetail?.result?.location?.town}</b></h1>
    </div>
     
      <table className='tabloStili'>
           <tbody>
           
               <tr >
                 <td  style={hücreStili} className="sütun-2-satır" colSpan={2}><b className=''>{cardDetail?.city} {cardDetail?.address}</b></td>
                 
               </tr>
               
               <tr >
                 <td style={hücreStili} className="sütun-2-satır"><b className="">İlan No:</b> </td>
                 <td style={hücreStili} className="sütun-2-satır" >{cardDetail?.result?.id}</td>
               </tr>

               <tr >
                 <td style={hücreStili} className="sütun-2-satır" ><b className="">İlan Tarihi</b> </td>
                 <td style={hücreStili} className="sütun-2-satır" >{formattedDate} </td>
               </tr>

               <tr >
                 <td style={hücreStili} className="sütun-2-satır" ><b className="">İlan Kategori</b> </td>
                 <td style={hücreStili} className="sütun-2-satır" > {emlak} {kategori}  </td>
               </tr>

               <tr >
                 <td style={hücreStili} className="sütun-2-satır" ><b className="">Emlak Türü</b> </td>
                 <td style={hücreStili} className="sütun-2-satır" >{tip}  </td>
               </tr>

               

             

                {kredi ? 
               <tr >
                 <td style={hücreStili} className="sütun-2-satır" > <b className="">Krediye Uygunluk</b> </td>
                 <td style={hücreStili} className="sütun-2-satır" > {kredi}  </td>
               </tr>
              : ''}    

          

               {tapu_durum ? 
               <tr >
                 <td style={hücreStili} className="sütun-2-satır" > <b className="">Tapu Durumu </b> </td>
                 <td style={hücreStili} className="sütun-2-satır" > {tapu_durum}  </td>
               </tr>
              : ''}

              {oda_sayisi ? 
               <tr >
                 <td style={hücreStili} className="sütun-2-satır" > <b className="">Oda Sayısı: </b> </td>
                 <td style={hücreStili} className="sütun-2-satır" > {oda_sayisi}  </td>
               </tr>
              : ''} 
               
               {metrekare ? 
               <tr >
                 <td style={hücreStili} className="sütun-2-satır" > <b className="">m² (Metrekare)</b> </td>
                 <td style={hücreStili} className="sütun-2-satır" > {metrekare}  </td>
               </tr>
              : ''}

              
                {net_metrekare ? 
               <tr >
                 <td style={hücreStili} className="sütun-2-satır" > <b className="">m² (Net Metrekare)</b> </td>
                 <td style={hücreStili} className="sütun-2-satır" > {net_metrekare}  </td>
               </tr>
              : ''}

               {brut_metrekare ? 
               <tr >
                 <td style={hücreStili} className="sütun-2-satır" > <b className="">m² (Brüt Metrekare)</b> </td>
                 <td style={hücreStili} className="sütun-2-satır" > {brut_metrekare}  </td>
               </tr>
              : ''}


             {kat_sayisi ? 
               <tr >
                 <td style={hücreStili} className="sütun-2-satır" > <b className="">Kat Sayısı</b> </td>
                 <td style={hücreStili} className="sütun-2-satır" > {kat_sayisi}  </td>
               </tr>
              : ''}

           {bina_yasi ? 
               <tr >
                 <td style={hücreStili} className="sütun-2-satır" > <b className="">Bina Yaşı</b> </td>
                 <td style={hücreStili} className="sütun-2-satır" > {bina_yasi}  </td>
               </tr>
              : ''}

            {isitma_tipi ? 
               <tr >
                 <td style={hücreStili} className="sütun-2-satır" > <b className="">Isıtma</b> </td>
                 <td style={hücreStili} className="sütun-2-satır" > {isitma_tipi}  </td>
               </tr>
              : ''}


            {banyo_sayisi ? 
               <tr >
                 <td style={hücreStili} className="sütun-2-satır" > <b className="">Banyo Sayısı</b> </td>
                 <td style={hücreStili} className="sütun-2-satır" > {banyo_sayisi}  </td>
               </tr>
              : ''}

              {esya_durumu ? 
               <tr >
                 <td style={hücreStili} className="sütun-2-satır" > <b className="">Eşya Durumu</b> </td>
                 <td style={hücreStili} className="sütun-2-satır" > {esya_durumu}  </td>
               </tr>
              : ''}        

              {kullanim_durumu ? 
               <tr >
                 <td style={hücreStili} className="sütun-2-satır" > <b className="">Kullanım Durumu</b> </td>
                 <td style={hücreStili} className="sütun-2-satır" > {kullanim_durumu}  </td>
               </tr>
              : ''}    

            {takas ? 
                 <tr >
                   <td style={hücreStili} className="sütun-2-satır" > <b className="">Takas Durumu</b> </td>
                   <td style={hücreStili} className="sütun-2-satır" > {takas}  </td>
                 </tr>
                : ''}   


                
              {kat_karsiligi ? 
                 <tr >
                   <td style={hücreStili} className="sütun-2-satır" > <b className="">Kat Karşılığı</b> </td>
                   <td style={hücreStili} className="sütun-2-satır" > {kat_karsiligi}  </td>
                 </tr>
                : ''}  

             


            
     
             
           </tbody>
         </table>

   </div>
    
  </div>


</div>


    



    <div className='açıklama' style={{margin:"2rem"}}>
    <h2 className='text-xl sm:text-2xl underline font-bold' style={{ textAlign: "center", padding:"5vh" }}>İlan Açıklaması</h2>
                  <span className="" style={{ textAlign: "center" ,margin:"2rem 2rem"}}>
             
               <div dangerouslySetInnerHTML={{ __html: aciklama }} />

               </span>
    </div>
    {/* <div className='flex align-center justify-center '>  <div className="w-full m-7">
                  
                      <br />
                      <Iframe url="https://maps.google.com/maps?width=800&amp;height=400&amp;hl=tr&amp;q=Maya%20Emlak%20-%20Band%C4%B1rma%20Band%C4%B1rma+(Maya%20Emlak%20-%20Band%C4%B1rma)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
        
                       width="100%"
                       height="400"
                       margin="0"
                       padding="0"
                       id=""
                       className=""
                            
                    /> 	
                    </div>
    </div> */}
    </div>

    </div>
      
      
      )
};

export default Property;
