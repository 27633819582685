export const navLinks = [
  {
    id: 1,
    linkText: "Anasayfa",
    url: "/",
   
  },
  {
    id: 2,
    linkText: "İlanlar",
    url: "/adverts",
   
  },
  {
    id: 3,
    linkText: "Satılık",
    url: "/sale",
   
  },
  {
    id: 4,
    linkText: "Kiralık",
    url: "/rent",
   
  },
  {
    id: 5,
    linkText: "Fiyatı Düşen İlanlar",
    url: "/cheapAdverts",
   
  },
  {
    id: 5,
    linkText: "Şubelerimiz",
    url: "/points",
    subLinks: [
      {
        id: 1,
        linkText: "Kırklareli",
        url: "/team1",
      },
      {
        id: 2,
        linkText: "Bandırma",
        url: "/team2",
      },
      {
        id: 3,
        linkText: "Gönen",
        url: "/team3",
      },
      {
        id: 4,
        linkText: "Çorlu",
        url: "/team4",
      },
    ],
   
  },
 
  {
    id: 6,
    linkText: "Hakkımızda",
    url: "/about",
   
   
  },
  {
    id: 7,
    linkText: "İletişim",
    url: "/contact",
   
  },
  
];


export const sube = [
  {
    id: 1,
    linkText: "Kırklareli",
    url: "/team1",
   
  },
  {
    id: 2,
    linkText: "Bandırma",
    url: "/team2",
   
  },
  {
    id: 3,
    linkText: "Gönen",
    url: "/team3",
   
  },
  {
    id: 4,
    linkText: "Çorlu",
    url: "/team4",
   
  }
 
 

  
];
