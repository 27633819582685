
import useProperties from "../../hooks/useProperties";

import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import SingleProductCard from "./SingleProductCard";
import { PuffLoader } from "react-spinners";
import SingleCheapAdvertCard from "../../pages/CheapAdverts/SingleCheapAdvertCard";
import useEmlakjetProperties from "../../hooks/useEmlakjetProperties";


const AdvertCardListThree = () => {

    const history = useHistory();

  
    const { data, isError, isLoading } = useEmlakjetProperties();
    useEffect(() => {
     
  
  
      window.scrollTo(0, 0);
    
        
  
    }, []);

    if (!data || data.length === 0) {
        return  <div className="wrapper flexCenter" style={{ height: "60vh" }}>
        <PuffLoader
          height="80"
          width="80"
          radius={1}
          color="#4066ff"
          aria-label="puff-loading"
        />
      </div>;
      }
  return (

    <div className="px-[3%] md:px-[6%]">
    <div className="pt-10 pb-16">
      <div className="text-center">
      <a href="/adverts"><h1 className="mx-auto sub-heading">TÜMÜNÜ GÖR</h1></a>
        <h1 className="heading">Listelenen Yeni İlanlar</h1>
      </div>
      <div className="flex flex-wrap gap-4 mt-8">
        {data.slice(0, 4).map((card, i) => (
         <SingleCheapAdvertCard  card={card} key={i} />
        ))}
      </div>
    </div>
    </div>
  );
};

export default AdvertCardListThree;
