import { setCardDetail } from "../slice/cardDetailSlice";

export const fetchCardDetail = (id) => async (dispatch) => {
    try {
      const response = await fetch(`https://mayadangayrimenkul.com.tr/api/advertDetail/${id}`);
      if (!response.ok) {
        throw new Error('Kart detayı yüklenemedi.');
      }
      const data = await response.json();
  
      // Kart detaylarını Redux store'a yükle
      dispatch(setCardDetail(data));
    } catch (error) {
      console.error('Hata:', error);
      throw error;
    }
  };
  