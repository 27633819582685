import { configureStore } from '@reduxjs/toolkit';
import cardDetailReducer from './slice/cardDetailSlice'; // Slice dosyanızı import edin
import pointDetailReducer from './slice/pointDetailSlice'; // Slice dosyanızı import edin
import memberDetailReducer from './slice/memberDetailSlice'; // Slice dosyanızı import edin
import uiReducer from './slice/uiSlice';
import dataReducer from "./slice/dataSlice";

const store = configureStore({
  reducer: {
    memberDetail: memberDetailReducer,
    pointDetail: pointDetailReducer,
    cardDetail: cardDetailReducer, // Slice'ı store'a ekleyin
    ui: uiReducer,
    data: dataReducer,
  },
});

export default store;