import React from "react";
import { useQuery } from "react-query";

import { getAllLinks } from "../utils/api";

const useLinks = () => {
  const { data, isLoading, isError, refetch } = useQuery(
    "allLinks",
    getAllLinks,
    { refetchOnWindowFocus: false }
  );

  return {
    data,
    isError,
    isLoading,
    refetch,
  };
};

export default useLinks;
