import { createSlice } from '@reduxjs/toolkit';

const pointDetailSlice = createSlice({
  name: 'pointDetail',
  initialState: null,
  reducers: {
    setPointDetail: (state, action) => action.payload,
    clearPointDetail: () => null,
  },
});

export const { setPointDetail, clearPointDetail } = pointDetailSlice.actions;

export default pointDetailSlice.reducer;