import React from "react"
import AboutUs from "../AboutUs/AboutUs"
import FaqsList from "../Faqs/FaqsList"
import Back from "../common/Back"
import Heading from "../common/Heading"
import img from "../images/property(1).jpg"
import "./about.css"
import { Route } from "react-router-dom"

const About = () => {

  const ScrollToTop = () => {
    window.scrollTo(0, 0);
    return null;
  };
  return (
    <>
      <Route component={ScrollToTop} />

      <section className='about'>
        <Back name='Biz Kimiz' title='Hakkımızda' cover={img} />
        <div className="pt-20 px-[3%] md:px-[6%]">

         <AboutUs />

         <FaqsList />
         </div>
      </section>
    </>
  )
}

export default About
