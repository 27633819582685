import React, { useEffect, useState } from "react";
import houses from "../../assets/advert_search.jpg";
import HeroBtn from "./HeroBtn";
import { RiSearchLine, RiWindowsFill } from "react-icons/ri";
import { useHistory } from "react-router-dom";

const HeroSearch = ({filter,setFilter,trade}) => {



    const [searchValue, setSearchValue] = useState("");
    const [searchParametre, setSearchParametre] = useState("");



 
    const handleInputChange = (e) => {
      if ((e.key === "Delete" || e.key === "Backspace" || e.keyCode === 46 || e.keyCode === 8)  && e.target.value === "") {
        return;
      }
    
        // Input değeri değiştiğinde localfilter state'ini güncelle
        setSearchValue(e.target.value); // searchValue'i güncelle
      

      };

      const handleSearchClick = () => {
        // Butona tıklandığında setFilter işlemini çalıştır
        setFilter(searchValue); // searchValue'i kullanın
    
     


      };

      const history = useHistory();

     const goToRoute = () => {
       // Belirli bir route'a yönlendirme yapma
       history.push("/adverts");
       window.location.reload(true);
     };

     const handleEnterKeyPress = (e) => {
        // Enter tuşuna basıldığında handleSearchClick çalıştırılacak
        if (e.key === "Enter") {
          handleSearchClick();
        }
      };
     
      useEffect(() => {

        const searchParams = new URLSearchParams(window.location.search);
        const searchVal = searchParams.get("search") || "";
        setSearchParametre(searchVal);
        if (searchVal !== null) {
           
            setFilter(searchVal);
           /*  goToRoute(); */
        }
        else {
            console.log("search param yok")
        }
       

      },[searchValue])
      
      

    let buttons= [];
    if(trade && trade==="Kiralık")
    {

       buttons = [
        { title: "TÜM İLANLAR", to:"/adverts"},
        { title: "SATILIK", to:"/sale"  },
        { title: "KİRALIK",underline: "bg-[#FFFFFF]", to:"/rent" },
     
        { title: "KONUT" ,to:"/house" },
        { title: "ARSA", to:"/land"  },
        { title: "İŞ YERİ" , to:"/workplace" },
        { title: "TURİSTİK", to:"/touristy" }
        
      ];

    }

    if(trade && trade==="Satılık")
    {

       buttons = [
        { title: "TÜM İLANLAR",to:"/adverts"},
        { title: "SATILIK",underline: "bg-[#FFFFFF]", to:"/sale"  },
        { title: "KİRALIK" , to:"/rent" },
       
        { title: "KONUT" , to:"/house" },
        { title: "ARSA" , to:"/land"  },
        { title: "İŞ YERİ" , to:"/workplace" },
        { title: "TURİSTİK" , to:"/touristy" }
        
      ];

    }

    if(trade && trade==="House")
    {

       buttons = [
        { title: "TÜM İLANLAR",to:"/adverts"},
        { title: "SATILIK", to:"/sale"  },
        { title: "KİRALIK" , to:"/rent" },
       
        { title: "KONUT",underline: "bg-[#FFFFFF]", to:"/house" },
        { title: "ARSA", to:"/land"  },
        { title: "İŞ YERİ" , to:"/workplace" },
        { title: "TURİSTİK" , to:"/touristy" },
        
      ];

    }

    if(trade && trade==="Arsa")
    {

       buttons = [
        { title: "TÜM İLANLAR",to:"/adverts"},
        { title: "SATILIK", to:"/sale"  },
        { title: "KİRALIK" , to:"/rent" },
       
        { title: "KONUT", to:"/house" },
        { title: "ARSA",underline: "bg-[#FFFFFF]", to:"/land"  },
        { title: "İŞ YERİ" , to:"/workplace" },
        { title: "TURİSTİK" , to:"/touristy" }
        
      ];

    }

    if(trade && trade==="İşyeri")
    {

       buttons = [
        { title: "TÜM İLANLAR",to:"/adverts"},
        { title: "SATILIK", to:"/sale"  },
        { title: "KİRALIK" , to:"/rent" },
       
        { title: "KONUT", to:"/house" },
        { title: "ARSA", to:"/land"  },
        { title: "İŞ YERİ", underline: "bg-[#FFFFFF]", to:"/workplace" },
        { title: "TURİSTİK" , to:"/touristy" }
        
      ];

    }

    if(trade && trade==="Turistik tesis")
    {

       buttons = [
        { title: "TÜM İLANLAR",to:"/adverts"},
        { title: "SATILIK", to:"/sale"  },
        { title: "KİRALIK" , to:"/rent" },
       
        { title: "KONUT", to:"/house" },
        { title: "ARSA", to:"/land"  },
        { title: "İŞ YERİ", to:"/workplace" },
        { title: "TURİSTİK",underline: "bg-[#FFFFFF]", to:"/touristy" },
        
      ];

    }

    if(!trade) {
      buttons = [
        { title: "TÜM İLANLAR",underline: "bg-[#FFFFFF]",to:"/adverts"},
        { title: "SATILIK" , to:"/sale"  },
        { title: "KİRALIK" , to:"/rent" },
       
        { title: "KONUT" ,to:"/house" },
        { title: "ARSA" , to:"/land"  },
        { title: "İŞ YERİ" , to:"/workplace" },
        { title: "TURİSTİK", to:"/touristy" },
        
      ];

    }
  
  return (

    
    <div className="h-[25rem] sm:h-[27rem] flex  ">
      <div className="absolute  w-full      ">
        <div className=" absolute w-full h-[25rem]  "></div>
        <img
          src={houses}
          className="object-cover w-full  h-[25rem] sm:h-[27rem] "
        />
      </div>
      <div className="relative flex flex-col items-center w-full pt-12 text-center">
        <p className="text-white px-2  text-[32px] sm:text-[57px] font-bold">
        İlanlarımız
        </p>
        
        {searchParametre || filter  ?  (<p className="text-white px-2 pt-2 sm:pt-0  sm:text-[23px]">"{filter}" için arama sonuçları listelenmektedir.</p>) : (<p className="text-white px-2 pt-2 sm:pt-0  sm:text-[23px]"> Beklentilerinizin ötesinde hizmet anlayışımızla  yanınızdayız...</p>)}
        
        <ul className="">
        <div className="pt-8 flex text-center items-center">
            {buttons.map((button) => (
              <HeroBtn
                to={button.to}
                title={button.title}
                underline={button.underline}
                css={button.css}
              />
            ))}
          </div>
        </ul>
        {/* Input */}
        <div className="relative mt-9 mx-4 sm:mx-0"> {/* 'mx-4' ekledik */}
        {searchParametre  ? ( 
             <input
             type="search"
             className="bg-white py-4 w-full sm:w-[37rem] rounded-full pl-5 placeholder:text-gray-500 placeholder:text-[11px] sm:placeholder:text-[14px]  outline-0"
             placeholder="Adres, Şehir, Zip Kodu, Mahalle Ara.."
             value={searchValue}
             onFocus={goToRoute}
             onKeyDown={handleEnterKeyPress}
           />
        ) : (
            <input
            type="search"
            className="bg-white py-4 w-full sm:w-[37rem] rounded-full pl-5 placeholder:text-gray-500 placeholder:text-[11px] sm:placeholder:text-[14px]  outline-0"
            placeholder="Adres, Şehir, Mahalle, İlan Ara.."
            value={searchValue}
            onInput={handleInputChange}
            onKeyDown={handleEnterKeyPress}
          />
        )}
        
         <div className="absolute w-[2.7rem] h-[2.7rem] rounded-full bg-[#2B2B2B] top-[0.4rem] right-1 flex items-center justify-center">

            <button  onClick={handleSearchClick}><RiSearchLine className="text-white text-[22px]" /></button>
         </div>
       </div>

      </div>
    </div>
  );
};

export default HeroSearch;
