import React, { useState } from "react";
import houses from "../../assets/city.jpg";
import HeroBtn from "./HeroBtn";
import { RiSearchLine } from "react-icons/ri";
import { useHistory } from "react-router-dom";
import { PuffLoader } from "react-spinners";

const Hero = () => {
  const [filter, setFilter] = useState("");
  const history = useHistory();

  const [imageLoaded, setImageLoaded] = useState(false);
  // Resim yüklendiğinde bu işlevi çağırın
  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  const handleSearch = () => {
    // Arama butonuna tıkladığınızda, /adverts yoluna yönlendirme yapılır
    history.push(`/adverts?search=${filter}`);
  };
  
  const handleEnterKeyPress = (e) => {
    // Enter tuşuna basıldığında handleSearchClick çalıştırılacak
    if (e.key === "Enter") {
      handleSearch();
    }
  };
  const buttons = [
    { title: "TÜM İLANLAR", underline: "bg-[#FFFFFF]", to:"/adverts"},
    { title: "SATILIK", to:"/sale"},
    { title: "KİRALIK",to:"/rent" },
    { title: "KONUT", to:"/house" },
    { title: "ARSA" , to:"/land"  },
    { title: "İŞ YERİ", to:"/workplace" },
    { title: "TURİSTİK" , to:"/touristy" }
    
  ];
  return (
    <>
 
   {!imageLoaded ? (<div className="h-[25rem] sm:h-[27rem] flex ">
       <div className="absolute  w-full      ">
             <div className=" absolute w-full h-[25rem]  "></div>

             <div className="wrapper flexCenter" >
             <PuffLoader
               height="80"
               width="80"
               radius={1}
               color="#4066ff"
               aria-label="puff-loading"
             />
           </div>
         
             <img
               src={houses}
               className="object-cover w-full  h-[25rem] sm:h-[27rem] hidden"
               onLoad={handleImageLoad}
             />
       </div> 
    
      </div>
         ) : ( <div className="h-[25rem] sm:h-[27rem] flex  ">
       
       <div className="absolute  w-full      ">
         <div className=" absolute w-full h-[25rem]  "></div>
         
         <img
           src={houses}
           className="object-cover w-full  h-[25rem] sm:h-[27rem] "
           onLoad={handleImageLoad}
         />
       </div>
       <div className="relative flex flex-col items-center w-full sm:pb-16 text-center">
         <p className="text-white px-2  text-[32px] sm:text-[57px] font-bold [text-shadow:_0_1px_0_rgb(0_0_0_/_40%)]" >
         Maya Gayrimenkul & Yatırım Danışmanlığı
         </p>
         <p className="text-white px-2 pt-2 sm:pt-0  sm:text-[23px]  ">
         Beklentilerinizin ötesinde hizmet anlayışımızla yanınızdayız...
         </p>
         <ul className="">
         <div className="pt-8 flex text-center items-center">
           {buttons.map((button) => (
             <HeroBtn

               to={button.to}
               title={button.title}
               underline={button.underline}
               css={button.css}
             />
           ))}
         </div>
         
         </ul>
         {/* Input */}
         <div className="relative mt-9 mx-4 sm:mx-0"> {/* 'mx-4' ekledik */}
          <input
            type="search"
            className="bg-white py-4 w-full sm:w-[37rem] rounded-full pl-5 placeholder:text-gray-500 placeholder:text-[11px] sm:placeholder:text-[14px]  outline-0"
            placeholder="Adres, Şehir, Mahalle, İlan Ara.."
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
            onKeyDown={handleEnterKeyPress}
 
          />
          <div className="absolute w-[2.7rem] h-[2.7rem] rounded-full bg-[#2B2B2B] top-[0.4rem] right-1 flex items-center justify-center">
            <button  onClick={handleSearch}><RiSearchLine className="text-white text-[22px]" /></button>
          </div>
        </div>
 
       </div>
     </div>)
     }
    
    </>
    
  );
};

export default Hero;
